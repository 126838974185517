<template>
  <b-modal
    id="role-modal"
    centered
    size="lg"
    title="สร้างสิทธิ์การใช้งาน"
    @hidden="onClose"
    :hide-footer="true"
  >
    <b-form ref="form" class="form" @submit.stop.prevent="add">
      <!--begin::Scroll-->
      <div
        class="d-flex flex-column scroll-y mr-n7 pr-7"
        style="max-height: 741px"
      >
        <!--begin::Input group-->
        <b-alert v-if="messageError" variant="danger" show>{{
          messageError
        }}</b-alert>
        <div class="fv-row mb-10 fv-plugins-icon-container">
          <!--begin::Label-->
          <label class="fs-5 fw-bolder form-label mb-2">
            <span class="required">Role name</span>
          </label>
          <!--end::Label-->
          <!--begin::Input-->
          <b-form-input
            class="form-control-solid"
            placeholder="Enter a role name"
            :state="validateState('role_name')"
            v-model="systemRole.role_name"
          />
          <!--end::Input-->
          <div class="fv-plugins-message-container invalid-feedback"></div>
        </div>
        <!--end::Input group-->
        <!--begin::Permissions-->
        <div class="fv-row" v-if="permissionList">
          <!--begin::Label-->
          <label class="fs-5 fw-bolder form-label mb-2">Role Permissions</label>
          <!--end::Label-->
          <!--begin::Table wrapper-->
          <div class="table-responsive">
            <!--begin::Table-->
            <table class="table align-middle table-row-dashed fs-6 gy-5">
              <!--begin::Table body-->
              <tbody class="text-gray-600 fw-bold">
                <!--begin::Table row-->
                <tr>
                  <td class="text-gray-800">
                    Administrator Access
                    <i
                      class="fas fa-exclamation-circle ms-1 fs-7"
                      data-bs-toggle="tooltip"
                      title=""
                    ></i>
                  </td>
                  <td>
                    <!--begin::Checkbox-->
                    <label
                      class="form-check form-check-custom form-check-solid mr-9"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :checked="is_all"
                        @click="checkAll"
                      />
                      <span class="form-check-label">Select all</span>
                    </label>
                    <!--end::Checkbox-->
                  </td>
                </tr>
                <!--end::Table row-->
                <!--begin::Table row-->
                <tr v-for="item in permissionList.records" :key="item._id">
                  <td class="text-gray-800">{{ item.permissionTitle }}</td>
                  <td>
                    <!--begin::Wrapper-->
                    <div class="d-flex">
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-sm form-check-custom form-check-solid mr-5 mr-lg-20"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :checked="isCheckedPermission(item, 'readableRole')"
                          @change="
                            checkPermission($event, item, 'readableRole')
                          "
                        />
                        <span class="form-check-label">Read</span>
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Checkbox-->
                      <label
                        class="form-check form-check-sm form-check-custom form-check-solid mr-5 mr-lg-20"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :checked="isCheckedPermission(item, 'writeableRole')"
                          @change="
                            checkPermission($event, item, 'writeableRole')
                          "
                        />
                        <span class="form-check-label">Write</span>
                      </label>
                      <!--end::Checkbox-->
                    </div>
                    <!--end::Wrapper-->
                  </td>
                </tr>
                <!--end::Table row-->
              </tbody>
              <!--end::Table body-->
            </table>
            <!--end::Table-->
          </div>
          <!--end::Table wrapper-->
        </div>
        <!--end::Permissions-->
      </div>
      <!--end::Scroll-->
      <!--begin::Actions-->
      <div class="text-center pt-15">
        <button @click="close" type="button" class="btn btn-white mr-3">
          Cancel
        </button>
        <button type="submit" class="btn btn-primary">
          <span class="indicator-label" v-if="!loading">Submit</span>
          <span class="indicator-progress" v-if="loading"
            >Please wait...
            <span
              class="spinner-border spinner-border-sm align-middle ml-2"
            ></span
          ></span>
        </button>
      </div>
      <!--end::Actions-->
      <div></div>
    </b-form>
  </b-modal>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import {
  GET_ROLE_MASTERS,
  CREATE_ROLE,
  GET_ROLE,
  UPDATE_ROLE
} from "@/graphql/admin/role";

export default {
  mixins: [validationMixin],
  props: ["refetch", "_id", "onClose"],
  data() {
    return {
      loading: false,
      is_all: false,
      messageError: "",
      systemRole: {
        role_name: "",
        permissionRole: []
      }
    };
  },
  validations: {
    systemRole: {
      role_name: {
        required
      },
      permissionRole: {}
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.systemRole[name];
      return $dirty ? !$error : null;
    },
    checkAll(e) {
      const { checked } = e.target;
      const list = [];
      if (checked) {
        for (let i = 0; i < this.permissionList.records.length; i++) {
          const item = this.permissionList.records[i];
          list.push({
            permissionId: item.permissionId,
            readableRole: true,
            writeableRole: true
          });
        }
      }
      this.is_all = checked;
      this.systemRole.permissionRole = list;
    },
    close() {
      this.$nextTick(() => {
        this.$bvModal.hide("role-modal");
      });
    },
    handleOkAdd(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.add();
    },
    isCheckedPermission(val, type) {
      const item = this.systemRole.permissionRole.find(
        x => x.permissionId === val.permissionId
      );
      return item ? item[type] : false;
    },
    checkPermission(e, val, type) {
      const { checked } = e.target;
      const item = this.systemRole.permissionRole.find(
        x => x.permissionId === val.permissionId
      );
      if (!checked) {
        this.is_all = false;
      }
      if (checked) {
        if (!item) {
          const newItem = {
            permissionId: val.permissionId,
            [type]: true
          };
          if (type === "writeableRole") {
            newItem.readableRole = true;
          }
          this.systemRole.permissionRole.push(newItem);
        } else {
          if (type === "writeableRole") {
            item.readableRole = true;
          }
          item[type] = true;
        }
      } else {
        if (!item) {
          const newItem = {
            permissionId: val.permissionId,
            [type]: false
          };
          if (type === "readableRole") {
            newItem.writeableRole = false;
          }
          this.systemRole.permissionRole.push(newItem);
        } else {
          if (type === "readableRole") {
            item.writeableRole = false;
          }
          item[type] = false;
        }
      }
    },
    async add() {
      this.$v.systemRole.$touch();
      if (this.$v.systemRole.$anyError) {
        return;
      }
      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      this.loading = true;

      if (this._id) {
        const {
          data: { updateRole }
        } = await this.$apollo.mutate({
          mutation: UPDATE_ROLE,
          variables: {
            input: {
              _id: this._id,
              role_name: this.$v.systemRole.role_name.$model,
              permissionRole: this.$v.systemRole.permissionRole.$model
            }
          }
        });
        this.loading = false;
        const { isSuccess, message } = updateRole;
        this.messageError = "";
        if (!isSuccess) {
          this.$message.error(message);
          this.messageError = message;
        } else {
          this.$message.success(message);
          this.$nextTick(() => {
            this.$bvModal.hide("role-modal");
          });
          if (this.refetch) {
            this.refetch();
          }
        }
        return;
      }

      const {
        data: { createRole }
      } = await this.$apollo.mutate({
        mutation: CREATE_ROLE,
        variables: {
          input: {
            role_name: this.$v.systemRole.role_name.$model,
            permissionRole: this.$v.systemRole.permissionRole.$model
          }
        }
      });
      this.loading = false;
      const { isSuccess, message } = createRole;
      this.messageError = "";
      if (!isSuccess) {
        this.$message.error(message);
        this.messageError = message;
      } else {
        this.$message.success(message);
        this.$nextTick(() => {
          this.$bvModal.hide("role-modal");
        });
        if (this.refetch) {
          this.refetch();
        }
      }
    }
  },
  apollo: {
    permissionList: {
      query: GET_ROLE_MASTERS
    },
    systemRole: {
      query: GET_ROLE,
      skip() {
        return !this._id;
      },
      variables() {
        return {
          _id: this._id
        };
      },
      update: ({ systemRole }) => {
        const data = { ...systemRole.data };
        data.permissionRole = data.permissionRole.map(x => ({
          permissionId: x.permissionId,
          readableRole: x.readableRole,
          writeableRole: x.writeableRole
        }));
        return data;
      },
      result({ data, loading }) {
        if (!loading) {
          const { systemRole } = data;
          let count = 0;
          for (let i = 0; i < systemRole.data.permissionRole.length; i++) {
            const permission = systemRole.data.permissionRole[i];
            if (permission.readableRole && permission.writeableRole) {
              count += 1;
            }
          }
          this.is_all = count === this.permissionList.records.length;
        }
      }
    }
  }
};
</script>
